module.exports = {
  APP_ID: "317696dd-0e3b-4117-9694-ca65942c02f3",
  // APP_URI: "https://www.acv.app",
  APP_URI: "https://localhost:3000",
  MS_AUTH: "https://login.microsoftonline.com/common",
  // ENDPOINT: "https://acv-app-api-preprod.azurewebsites.net",
  ENDPOINT: "http://localhost:8000",
  ACV_URL: "https://demo.acv.app",
  DOC_URL: "https://docs.acv.app",
  SCOPES: "User.Read",
  GOOGLE_CLIENT_ID:
    "568178957537-5gsg4iso5qssiodrd11hfegql13dr7ve.apps.googleusercontent.com",
  STORAGE: "acvstorageprod",
};
